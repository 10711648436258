export const anchorsInit = (anchors) => dispatch => {
	dispatch({
		type: 'ANCHORS_INIT',
		anchors
	})
}

export const anchorUpdate = (slug, active) => dispatch => {
	dispatch({
		type: 'ANCHOR_UPDATE',
		slug,
		active
	})
}