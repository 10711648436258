import React, { Component } from 'react'
import { connect } from 'react-redux'
import Waypoint from 'react-waypoint'

import * as actions from '../../../state/actions/anchors'

class Anchor extends Component{

	handleChange = (props) => {
		const { currentPosition} = props
		const { anchorUpdate, attrs } = this.props

		currentPosition === "above" ? anchorUpdate(attrs.slug, true) : anchorUpdate(attrs.slug, false)
	}

	render(){
		const {attrs} = this.props
		return(
			<>
				<div className="anchor-point">
					<Waypoint onEnter={this.handleChange} onLeave={this.handleChange} />
				</div>
				<h2 id={attrs.slug} className="anchor-title">
					{attrs.linkName ? attrs.linkName : attrs.title}
				</h2>
			</>
		)
	}
}

export default connect(null, actions)(Anchor)
